import { useAccountStore } from "@/stores/account"
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from "#app"

export default defineNuxtRouteMiddleware(async (to) => {
  const accountStore = useAccountStore()
  if (useNuxtApp().nuxt2Context.$auth.isValidSsoAuthRedirectedUrl()) {
    await accountStore.login()
  }

  if (accountStore.token === null) {
    accountStore.redirectUrl = to.fullPath

    return navigateTo("/users/signin")
  }

  if (
    (accountStore.token && useNuxtApp().nuxt2Context.$auth.isTokenExpired()) ||
    accountStore.email === "construction@evercam.io"
  ) {
    await accountStore.logout({ revoke: false })

    return {}
  }
})
