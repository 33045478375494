import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import {
  ProjectFeatureFlag,
  TaskStatus,
  AdminCamera,
  CameraExid,
  GateReportVehicleType,
  GateReportEvent,
  DailyEventCount,
  DateType,
  Project,
  Date_YYYY_MM_DD,
  GlobalSearchResult,
  CameraFeatureFlag,
} from "@evercam/shared/types"
import { camelizeKeys } from "humps"
import { VEHICLE_TYPES } from "@evercam/shared/constants/gateReport"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { AiApi } from "@evercam/shared/api/aiApi"
import axios from "@evercam/shared/api/client/axios"
import { UPDATE_TYPE } from "@/stores/gateReportEventsValidation"
import { useAccountStore } from "@/stores/account"
import { useDayVerificationStore } from "@/stores/dayVerification"
import { useNuxtApp, useRuntimeConfig } from "#app"

type SearchParams = {
  isVerified: number
  cameras: CameraExid[]
  vehicleTypes: GateReportVehicleType[]
}

type FilteredEventsParams = {
  events?: GateReportEvent[]
  searchParams?: Partial<SearchParams>
  isGlobalSearchActive?: boolean
  globalSearchResults?: GlobalSearchResult[]
}

type GateReportState = {
  availableSnapshotsHours: number[]
  cameras: AdminCamera[]
  dailyCounts: DailyEventCount[]
  dailyStatus: Date_YYYY_MM_DD[]
  events: GateReportEvent[]
  filteredEventsCount: number
  isLoading: boolean
  isLoadingEvents: boolean
  showControls: boolean
  selectedCamera: AdminCamera
  selectedCameraExids: CameraExid[]
  selectedDate: DateType
  selectedProject: Project
  selectedVehicleTypes: GateReportVehicleType[]
  selectedVerifiedEventsVisibility: number
  tasksStatuses: Record<string, TaskStatus>
  unverifiedFilteredEventsCount: number
  vehicleTypes: typeof VEHICLE_TYPES
  verifiedEventsVisibilityItems: { text: string; value: 0 | boolean }[]
  verifiedFilteredEventsCount: number
}

export const useGateReportStore = defineStore({
  id: "gateReport",
  state: (): GateReportState => ({
    availableSnapshotsHours: [],
    cameras: [],
    dailyCounts: [],
    dailyStatus: [],
    events: [],
    filteredEventsCount: 0,
    isLoading: false,
    isLoadingEvents: false,
    showControls: true,
    selectedCamera: null,
    selectedCameraExids: [],
    selectedDate: new Date(),
    selectedProject: null,
    selectedVehicleTypes: [],
    selectedVerifiedEventsVisibility: 0,
    tasksStatuses: {
      fetchAvailableSnapshotsHours: TaskStatus.Idle,
      fetchVerifiedDays: TaskStatus.Idle,
      fetchDailyStatus: TaskStatus.Idle,
      fetchDailyCounts: TaskStatus.Idle,
      fetchEvents: TaskStatus.Idle,
    },
    unverifiedFilteredEventsCount: 0,
    vehicleTypes: VEHICLE_TYPES,
    verifiedEventsVisibilityItems: [
      { text: "All Events", value: 0 },
      { text: "Verified Events", value: true },
      { text: "Unverified Events", value: false },
    ],
    verifiedFilteredEventsCount: 0,
  }),
  getters: {
    selectedCameraExid(): string {
      return this.selectedCamera?.exid
    },
    searchParams(): SearchParams {
      return {
        isVerified: this.selectedVerifiedEventsVisibility,
        cameras: this.selectedCameraExids,
        vehicleTypes: this.selectedVehicleTypes,
      }
    },
    selectedCameras(): AdminCamera[] {
      return this.cameras.filter((camera) =>
        this.selectedCameraExids.includes(camera.exid)
      )
    },
    isAutoVerifyEnabled() {
      return this.selectedProject?.featureFlags.includes(
        ProjectFeatureFlag.GateReportAutoVerify
      )
    },
    breadcrumbsTitle() {
      const { exid, name } = this.selectedProject
      const date = useNuxtApp()
        .nuxt2Context.$moment(this.selectedDate)
        .format("DD/MM/YYYY")

      const page = `Events verification ${
        this.isAutoVerifyEnabled ? "(auto-verified)" : ""
      }`

      return `${page} - ${name} (${exid}) - ${date}`
    },
    getFilteredEvents() {
      return ({
        events = [],
        searchParams = {},
        isGlobalSearchActive = false,
        globalSearchResults = [],
      }: FilteredEventsParams) => {
        if (isGlobalSearchActive) {
          return globalSearchResults.map((match) => events[match.index])
        }
        const { isVerified, cameras, vehicleTypes } = searchParams
        if (!isVerified && !cameras?.length && !vehicleTypes?.length) {
          return events
        }

        const uniqueEventsMap = events
          .reduce((map, event) => map.set(event.id, event), new Map())
          .values()
        const uniqueEvents = [...uniqueEventsMap]

        return uniqueEvents.filter((item) => {
          const visibilityCheck =
            isVerified === 0 || item.isPublic === isVerified
          const camerasCheck =
            cameras.length && cameras.includes(item.cameraExid)
          const vehicleTypesCheck =
            vehicleTypes.length && vehicleTypes.includes(item.truckType)

          return visibilityCheck && camerasCheck && vehicleTypesCheck
        })
      }
    },
  },
  actions: {
    updateTasksStatuses(payload = {}) {
      this.tasksStatuses = {
        ...this.tasksStatuses,
        ...payload,
      }
    },
    clearStates() {
      this.resetData()
      useDayVerificationStore().$reset()
    },
    resetData() {
      this.events = []
      this.dailyCounts = []
      this.dailyStatus = []
      this.selectedProject = null
      this.tasksStatuses = {
        fetchAvailableSnapshotsHours: TaskStatus.Idle,
        fetchVerifiedDays: TaskStatus.Idle,
        fetchDailyStatus: TaskStatus.Idle,
        fetchDailyCounts: TaskStatus.Idle,
        fetchEvents: TaskStatus.Idle,
      }
    },
    async init(projectExid) {
      this.clearStates()
      await this.selectProject(projectExid)
      const cameraExid = this.selectedProject?.cameras[0]?.exid
      await this.selectCamera(cameraExid)
    },
    async selectProject(exid) {
      try {
        let project = await AdminApi.projects.getAll({
          params: {
            exid: exid,
          },
        })
        let cameras = await AdminApi.cameras.getCameras({
          params: {
            projectName: project?.items[0]?.name,
            featureFlags: [
              CameraFeatureFlag.GateReport,
              CameraFeatureFlag.ANPR,
            ],
          },
        })
        project.items[0].cameras = cameras.items
        this.selectedProject = project.items[0]
        this.cameras = cameras.items
        this.selectedCameraExids = cameras.items.map((camera) => camera.exid)
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to load gate report project!",
          error,
        })
      }
    },
    async selectCamera(exid) {
      try {
        let camera = this.cameras.find((c) => c.exid === exid)
        camera.thumbnailUrl = `${useRuntimeConfig().apiURL}/cameras/${
          camera.exid
        }/thumbnail?api_id=${camera.userApiId}&api_key=${camera.userApiKey}`
        this.selectedCamera = camera
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to load gate report camera!",
          error,
        })
      }
    },
    async fetchEvents(startDate, endDate, camerasExids = []) {
      let isCancelled = false
      const projectExid = this.selectedProject?.exid
      const timezone = this.selectedProject?.timezone
      const day = useNuxtApp()
        .nuxt2Context.$moment(this.selectedDate)
        .format("YYYY-MM-DD")
      let cameras = {}
      if (camerasExids.length) {
        cameras = {
          camerasExid: camerasExids,
        }
      } else {
        cameras = {
          camerasExid: this.selectedCameraExids,
        }
      }
      this.isLoadingEvents = true
      this.events = []
      this.updateTasksStatuses({
        fetchEvents: TaskStatus.Loading,
      })
      try {
        // Cancel the previous requests
        axios.cancelRequests()

        // Add cancel tokens for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        const allEvents = await AiApi.gateReport.getAllEvents(
          projectExid,
          {
            fromDate:
              startDate ||
              useNuxtApp()
                .nuxt2Context.$moment.tz(`${day}T00:00:00`, timezone)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss"),
            toDate:
              endDate ||
              useNuxtApp()
                .nuxt2Context.$moment.tz(`${day}T23:59:59`, timezone)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss"),
            ...cameras,
            limit: 6000,
          },
          {
            cancelToken: cancelToken.token,
          }
        )

        this.events = allEvents.items
        this.updateTasksStatuses({
          fetchEvents: TaskStatus.Success,
        })
      } catch (error) {
        this.events = []
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch gate report events!",
          error,
        })
        isCancelled = axios.isCancel(error)
        this.updateTasksStatuses({
          fetchEvents: isCancelled ? TaskStatus.Idle : TaskStatus.Error,
        })
      } finally {
        if (!isCancelled) {
          this.isLoadingEvents = false
        }
      }
    },
    async fetchDailyCounts() {
      this.isLoading = true
      this.updateTasksStatuses({
        fetchDailyCounts: TaskStatus.Loading,
      })

      const projectExid = this.selectedProject?.exid

      try {
        const payload = {
          vehicleTypes: this.selectedVehicleTypes,
          camerasExid: this.selectedCameraExids,
          ...(this.selectedVerifiedEventsVisibility !== 0 && {
            isPublic: !!this.selectedVerifiedEventsVisibility,
          }),
        }
        const { days } = await AiApi.gateReport.getEventCounts(
          projectExid,
          payload
        )
        this.dailyCounts = days
        this.updateTasksStatuses({
          fetchDailyCounts: TaskStatus.Success,
        })
      } catch (error) {
        this.dailyCounts = []
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch gate report daily counts!",
          error,
        })
        this.updateTasksStatuses({
          fetchDailyCounts: TaskStatus.Error,
        })
      } finally {
        this.isLoading = false
      }
    },
    async fetchDailyStatus() {
      if (this.tasksStatuses.fetchDailyStatus === TaskStatus.Loading) {
        return
      }
      this.isLoading = true
      this.updateTasksStatuses({
        fetchDailyStatus: TaskStatus.Loading,
      })

      const projectExid = this.selectedProject?.exid

      try {
        const data = await AiApi.gateReport.getDailyStatus(projectExid, {
          isPublic: false,
          camerasExid: this.selectedCameraExids,
        })
        this.dailyStatus = data
        this.updateTasksStatuses({
          fetchDailyStatus: TaskStatus.Success,
        })
      } catch (error) {
        this.dailyStatus = []
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch gate report daily status!",
          error,
        })
        this.updateTasksStatuses({
          fetchDailyStatus: TaskStatus.Error,
        })
      } finally {
        this.isLoading = false
      }
    },
    async fetchVerifiedDays() {
      try {
        this.updateTasksStatuses({
          fetchVerifiedDays: TaskStatus.Loading,
        })
        await useDayVerificationStore().fetchVerifiedDays({
          projectExid: this.selectedProject?.exid,
          camerasExid: this.selectedCameraExids,
        })
        this.updateTasksStatuses({
          fetchVerifiedDays: TaskStatus.Success,
        })
      } catch (error) {
        this.updateTasksStatuses({
          fetchVerifiedDays: TaskStatus.Error,
        })
      }
    },
    async verifyDay() {
      useDayVerificationStore().verifyDay({
        date: this.selectedDate,
        projectExid: this.selectedProject?.exid,
        camerasExid: this.selectedCameraExids,
      })
    },
    async verifySingleEvent(event) {
      let response
      try {
        this.isLoading = true
        const params = {
          payload: { isPublic: event.isPublic },
          updatedBy: useAccountStore().email,
        }
        response = await AiApi.gateReport.updateEventById(event.id, params)
        useNuxtApp().nuxt2Context.$notifications.success(
          "Event verified successfully !"
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to verify the event!",
          error,
        })
      } finally {
        this.isLoading = false
      }

      return response
    },
    async fetchAvailableSnapshotsHours() {
      this.isLoading = true
      this.updateTasksStatuses({
        fetchAvailableSnapshotsHours: TaskStatus.Loading,
      })
      try {
        const selectedDate = useNuxtApp().nuxt2Context.$moment(
          this.selectedDate
        )
        const cameras = this.cameras
        for (const camera of cameras) {
          const { apiId, apiKey, exid } = camera
          const { hours } = await EvercamApi.recordings.availableHours({
            cameraId: exid,
            year: selectedDate.format("YYYY"),
            month: selectedDate.format("MM"),
            day: selectedDate.format("DD"),
            payload: {
              apiId,
              apiKey,
            },
          })
          camera.hours = hours
        }
        this.cameras = cameras
        this.updateTasksStatuses({
          fetchAvailableSnapshotsHours: TaskStatus.Success,
        })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch gate report daily counts!",
          error,
        })
        this.updateTasksStatuses({
          fetchAvailableSnapshotsHours: TaskStatus.Error,
        })
      } finally {
        this.isLoading = false
      }
    },
    updateMainPageEventsList({ event, updateType }) {
      if (updateType === UPDATE_TYPE.CREATE) {
        this.events = [event, ...this.events]
      } else if (updateType === UPDATE_TYPE.EDIT) {
        this.events = this.events.map((e) => (e.id === event.id ? event : e))
      } else if (updateType === UPDATE_TYPE.DELETE) {
        this.events = this.events.filter((e) => e.id !== event.id)
      }
    },
    updateStateFromUrl(queryParams) {
      const query = camelizeKeys(queryParams)
      this.selectedVerifiedEventsVisibility = JSON.parse(
        query?.verifiedEventVisibility || 0
      )

      if (!Array.isArray(query.vehicleTypes)) {
        this.selectedVehicleTypes = query.vehicleTypes
          ? [query.vehicleTypes]
          : this.selectedVehicleTypes
      } else {
        this.selectedVehicleTypes = query.vehicleTypes
      }

      if (!Array.isArray(query.cameras)) {
        this.selectedCameraExids = query.cameras
          ? [query.cameras]
          : this.selectedCameraExids
      } else {
        this.selectedCameraExids = query.cameras
      }
    },
  },
})
