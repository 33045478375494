import { useAccountStore } from "@/stores/account"
import { defineNuxtPlugin } from "#app"

export default defineNuxtPlugin((app) => {
  const accountStore = useAccountStore()

  const isAnnotator = () => {
    return [
      "annotators@evercam.io",
      "nadir.alloune@evercam.io",
      "ahmed.benketfi@evercam.io",
      "zakaria.megherbi@evercam.io",
      "aya.benbelkhir@evercam.io",
      "oussama.bahri@evercam.io",
      "abderrahmane.taibi@evercam.io",
      "oualid.saidkouadri@evercam.io",
      "assala.benbelkhir@evercam.io",
      "nesrine.saidkouadri@evercam.io",
      "daniel.caffrey@evercam.io ",
      "amine.maatalla@evercam.io",
      "meriem.athamena@evercam.io",
      "ahlem.laouar@evercam.io",
    ].includes(accountStore.email)
  }

  const isQA = () => {
    return ["younes.bensaada@evercam.io"].includes(accountStore.email)
  }

  const user = {
    is: {
      annotator: isAnnotator,
      qa: isQA,
    },
  }

  app.provide("permissions", { user })
})
