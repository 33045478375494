import { AdminApi } from "@evercam/shared/api/adminApi"
import {
  AdminCamera,
  AnprCount,
  AnprEvent,
  CameraExid,
  CameraFeatureFlag,
  DateType,
  Date_YYYY_MM_DD,
  GateReportVehicleType,
  Project,
  TimelinePrecision,
} from "@evercam/shared/types"
import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useDayVerificationStore } from "@/stores/dayVerification"
import moment from "moment-timezone"
import {
  VEHICLE_TYPES,
  VEHICLE_TYPE_IDS,
} from "@evercam/shared/constants/gateReport"

type CustomAnprEvent = AnprEvent & {
  thumbnails: { anpr: string; context: string }
}

enum UrlParams {
  SelectedDate = "selectedDate",
  SelectedCameraExids = "selectedCameraExids",
  SelectedVehicleTypes = "selectedVehicleTypes",
  PlateNumber = "plateNumber",
  Direction = "direction",
  ShowDuplicates = "showDuplicates",
  ShowFalsePositives = "showFalsePositives",
  ShowNotPostProcessed = "showNotPostProcessed",
  ShowPostProcessed = "showPostProcessed",
}

type AnprState = {
  dailyCounts: AnprCount[]
  dailyStatus: Date_YYYY_MM_DD[]
  cameras: AdminCamera[]
  selectedProject: Project
  selectedDate: DateType
  plateNumber: string
  direction: string
  showDuplicates: boolean
  showFalsePositives: boolean
  showNotPostProcessed: boolean
  showPostProcessed: boolean
  selectedCameraExids: CameraExid[]
  selectedVehicleTypes: GateReportVehicleType[]
  vehicleTypes: typeof VEHICLE_TYPES
  vehicleTypeIds: typeof VEHICLE_TYPE_IDS
  events: AnprEvent[]
  selectedEvent: CustomAnprEvent
  isLoadingEvents: boolean
}

export const useAnprStore = defineStore({
  id: "anpr",
  state: (): AnprState => ({
    dailyCounts: [],
    dailyStatus: [],
    cameras: [],
    selectedProject: null,
    selectedDate: moment().format("YYYY/MM/DD"),
    plateNumber: null,
    direction: null,
    showDuplicates: false,
    showFalsePositives: false,
    showNotPostProcessed: false,
    showPostProcessed: true,
    selectedCameraExids: [],
    selectedVehicleTypes: [],
    vehicleTypes: VEHICLE_TYPES,
    vehicleTypeIds: VEHICLE_TYPE_IDS,
    events: [],
    selectedEvent: {
      thumbnails: {
        anpr: "",
        context: "",
      },
    } as CustomAnprEvent,
    isLoadingEvents: false,
  }),
  actions: {
    clearStates() {
      this.$reset()
      useDayVerificationStore().$reset()
    },
    async init(projectExid) {
      this.clearStates()
      if (!this.selectedVehicleTypes?.length) {
        this.selectedVehicleTypes = this.vehicleTypeIds
      }
      await this.selectProject(projectExid)
      this.fetchVerifiedDays()
      this.fetchDailyCounts()
    },
    async selectProject(exid) {
      try {
        let project = await AdminApi.projects.getAll({
          params: {
            exid,
          },
        })
        let cameras = await AdminApi.cameras.getCameras({
          params: {
            projectName: project?.items[0]?.name,
            featureFlags: [
              CameraFeatureFlag.ANPR,
              CameraFeatureFlag.GateReport,
            ],
          },
        })
        project.items[0].cameras = cameras.items
        this.selectedProject = project.items[0]
        this.cameras = cameras.items
        if (!this.selectedCameraExids?.length) {
          this.selectedCameraExids = cameras.items.map((camera) => camera.exid)
        }
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to load gate report project!",
          error,
        })
      }
    },
    async fetchDailyCounts() {
      const projectExid = this.selectedProject?.exid

      try {
        const payload = {
          camerasExid: this.selectedCameraExids,
          precision: TimelinePrecision.Day,
        }
        this.dailyCounts = await AiApi.anpr.getAnprCounts(projectExid, payload)
      } catch (error) {
        this.dailyCounts = []
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch anpr daily counts!",
          error,
        })
      }
    },
    async fetchVerifiedDays() {
      await useDayVerificationStore().fetchVerifiedDays({
        projectExid: this.selectedProject?.exid,
        camerasExid: this.selectedCameraExids,
      })
    },
    async verifyDay() {
      useDayVerificationStore().verifyDay({
        date: this.selectedDate,
        projectExid: this.selectedProject?.exid,
        camerasExid: this.selectedCameraExids,
      })
    },
    async fetchEvents() {
      try {
        this.isLoadingEvents = true
        this.events = []
        const day = moment(this.selectedDate).format("YYYY-MM-DD")
        const formatDate = (d) =>
          moment
            .tz(d, this.selectedProject.timezone)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ssZ")

        const { items } = await AiApi.anpr.getAnprEvents(
          this.selectedProject.exid,
          {
            fromDate: formatDate(`${day}T00:00:00`),
            toDate: formatDate(`${day}T23:59:59`),
            limit: 2000,
            sort: "capture_time|asc",
          }
        )
        this.events = (items || []).map((event) => {
          return {
            ...event,
            captureTime: moment
              .tz(event.captureTime + "Z", this.selectedProject?.timezone)
              .format(),
          }
        })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch anpr events!",
          error,
        })
      } finally {
        this.isLoadingEvents = false
      }
    },
  },
  syncWithUrl: Object.values(UrlParams) as (keyof AnprState)[],
})
