import { CameraStatusMap } from "@/components/constants.js"

export default {
  name: "CameraUtils",
  methods: {
    getStatusColor(status) {
      return CameraStatusMap[status]?.class || ""
    },
    getStatusText(status) {
      return CameraStatusMap[status]?.value || status
    },
  },
}
