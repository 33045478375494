<template>
  <v-container fill-height fluid>
    <ERow align="center" justify="center">
      <ECol cols="4">
        <ERow align="center" justify="center">
          <v-icon class="grey--text error-icon v-icon--custom">
            fas fa-exclamation-circle
          </v-icon>
        </ERow>
        <ERow align="center" justify="center">
          <h1 v-if="error.statusCode === 404">
            {{ error.message || pageNotFound }}
          </h1>
          <h1 v-else>
            {{ otherError }}
          </h1>
        </ERow>
        <ERow align="center" justify="center">
          <div v-if="error.homePageLink !== false">
            <NuxtLink to="/"> Back to the home page </NuxtLink>
          </div>
        </ERow>
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
export default {
  layout: "empty",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: "404 Not Found",
      otherError: "An error occurred",
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError

    return {
      title,
    }
  },
}
</script>

<style scoped>
h1 {
  font-size: 20px;
}

.error-icon {
  font-size: 40px !important;
}
</style>
