import { defineStore } from "pinia"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { StorageServer } from "@evercam/shared/types/storageServers"
import { useNuxtApp } from "#app"

interface StorageState {
  storageServers: StorageServer[] | null
}

export const useStorageStore = defineStore({
  id: "storage",
  state: (): StorageState => ({
    storageServers: [],
  }),
  actions: {
    async fetchStorageServers() {
      if (this.storageServers.length) {
        return
      }

      try {
        const { servers } = await AdminApi.cameras.getStorageServers()
        this.storageServers = servers
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to fetch storage servers!",
          error,
        })
      }
    },
  },
})
