export default {
  name: "ResizeTable",
  data() {
    return {
      tableHeight: null,
      tableRef: "tableContainer",
      headerRef: "headerContainer",
    }
  },
  mounted() {
    this.$addEventListener("resize", this.onResize)
  },
  methods: {
    onResize() {
      this.tableHeight = null
      let headerAndFooterHeight =
          this.$refs[this.headerRef]?.getBoundingClientRect()?.height,
        top = this.$refs[this.tableRef]?.$el?.getBoundingClientRect()?.top
      headerAndFooterHeight += this.hideDefaultFooter ? 0 : 35
      this.tableHeight = window.innerHeight - (headerAndFooterHeight + top)
    },
  },
}
