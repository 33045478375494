<template>
  <v-app dark>
    <TheSnackbar />
    <v-main>
      <v-container class="pa-0">
        <nuxt />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TheSnackbar from "@/components/TheSnackbar"

export default {
  components: {
    TheSnackbar,
  },
}
</script>
