import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from "#app"

export default defineNuxtRouteMiddleware((to) => {
  const annotatorAllowedRoutes = ["/gate-report"]

  function isAllowedRoute(routes) {
    return routes.some((el) => {
      let regex = new RegExp(`^(${el}).*$`)

      return regex.test(to.path)
    })
  }

  if (
    useNuxtApp().nuxt2Context.$permissions.user.is.annotator() &&
    !isAllowedRoute(annotatorAllowedRoutes)
  ) {
    return navigateTo("/gate-report")
  }
})
