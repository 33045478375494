import { defineStore } from "pinia"
import { GateReportEventType, GateReportEvent } from "@evercam/shared/types"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useGateReportStore } from "@/stores/gateReport"
import { useAccountStore } from "@/stores/account"
import { useNuxtApp } from "#app"

export type GateReportEventOptional = {
  [K in keyof GateReportEvent]?: GateReportEvent[K]
}

type GateReportEventsValidationState = {
  eventsValidationDialog: boolean
  editedEvent: Partial<GateReportEvent>
  preselectedEventFromUrl: Partial<GateReportEvent> | null
  events: GateReportEvent[]
  selectedHour: number
  loadingEventsIds: number[]
  savedEventsIds: number[]
  erroredEventsIds: number[]
}

export const UPDATE_TYPE = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
}

export const useGateReportEventsValidationStore = defineStore({
  id: "gateReportEventsValidation",
  state: (): GateReportEventsValidationState => ({
    eventsValidationDialog: false,
    editedEvent: { eventType: GateReportEventType.Arrived },
    preselectedEventFromUrl: null,
    events: [],
    selectedHour: null,
    loadingEventsIds: [],
    savedEventsIds: [],
    erroredEventsIds: [],
  }),
  actions: {
    setEventLoading({ event, isLoading }) {
      if (isLoading) {
        this.loadingEventsIds = [
          ...this.loadingEventsIds,
          event.id || event.tempId,
        ]
      } else {
        this.loadingEventsIds = this.loadingEventsIds.filter(
          (id) => id !== event.id
        )
      }
    },
    setErroredEventsIds(event) {
      this.erroredEventsIds = [
        ...this.erroredEventsIds,
        event.id || event.tempId,
      ]
    },
    async deleteEvent(event) {
      try {
        this.setEventLoading({
          event,
          isLoading: true,
        })
        await AiApi.gateReport.deleteEvent(event.id, {
          createdBy: useAccountStore().email,
        })

        this.updateEventsLists({
          event,
          updateType: UPDATE_TYPE.DELETE,
        })

        this.savedEventsIds = this.savedEventsIds.filter(
          (id) => id !== event.id
        )
        useNuxtApp().nuxt2Context.$notifications.success(
          `Deleted event: ${event.id}`
        )
      } catch (error) {
        console.error(error)
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `Couldn't delete event: ${JSON.stringify(event)}`,
          error,
        })
        this.setErroredEventsIds(event)
      } finally {
        this.setEventLoading({
          event,
          isLoading: false,
        })
      }
    },
    async dismissEvents(events) {
      try {
        Object.values(events).map((event) => {
          this.setEventLoading({
            event,
            isLoading: true,
          })
        })
        await AiApi.gateReport.dismissEvents({
          eventsIds: Object.values(events).map((event) => event.id),
          createdBy: useAccountStore().email,
        })
        Object.values(events).map((event) => {
          this.updateEventsLists({
            event: event,
            updateType: UPDATE_TYPE.DELETE,
          })
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          "Deleted selected events succefully "
        )
      } catch (error) {
        console.error(error)
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't delete events",
          error,
        })
      } finally {
        Object.values(events).map((event) => {
          this.setEventLoading({
            event,
            isLoading: false,
          })
        })
      }
    },
    async updateEvent(event) {
      try {
        this.setEventLoading({
          event,
          isLoading: true,
        })
        const oldEvent = this.events.find((e) => e.id === event.id)

        const changes = await this.getEventChanges({
          oldEvent,
          newEvent: event,
        })

        if (Object.keys(changes).length) {
          const params = {
            payload: changes,
            updatedBy: useAccountStore().email,
          }
          await AiApi.gateReport.updateEventById(event.id, params)
        }

        this.updateEventsLists({
          event,
          updateType: UPDATE_TYPE.EDIT,
        })

        useNuxtApp().nuxt2Context.$notifications.success(
          `Updated event: ${event.id}`
        )
      } catch (error) {
        console.error(error)
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `Couldn't update event: ${JSON.stringify(event)}`,
          error,
        })
        this.setErroredEventsIds(event)
      } finally {
        this.setEventLoading({
          event,
          isLoading: false,
        })
      }
    },
    getEventChanges({ oldEvent, newEvent }) {
      return Object.entries(newEvent).reduce((changes, [key, value]) => {
        if (
          ["timestamp"].includes(key) ||
          value === undefined ||
          oldEvent[key] === value
        ) {
          return changes
        }

        if (key === "eventTime") {
          changes[key] = useNuxtApp()
            .nuxt2Context.$moment.tz(
              value,
              useGateReportStore().selectedProject?.timezone || "Europe/Dublin"
            )
            .format("YYYY-MM-DD HH:mm:ss")
        } else {
          changes[key] = value
        }

        return changes
      }, {})
    },
    updateEventsValidationEvents({ event, updateType }) {
      if (updateType === UPDATE_TYPE.CREATE) {
        this.events = this.events.map((e) =>
          e.tempId === event.tempId ? { ...event } : e
        )
      } else if (updateType === UPDATE_TYPE.EDIT) {
        this.events = this.events.map((e) =>
          e.id === event.id ? { ...event } : e
        )
      } else if (updateType === UPDATE_TYPE.DELETE) {
        this.events = this.events.filter((e) => e.id !== event.id)
      }
    },
    updateEventsLists({ event, updateType }) {
      this.updateEventsValidationEvents({ event, updateType })
      useGateReportStore().updateMainPageEventsList({ event, updateType })
    },
  },
})
