import { defineStore } from "pinia"
import {
  AdminCamera,
  CameraExid,
  Timestamp,
  CameraStatus,
} from "@evercam/shared/types"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { useReportStore } from "@/stores/report"
import { CAMERA_TABS } from "@/components/constants.js"
import { useNuxtApp } from "#app"
import {
  clearSubQuery,
  getSubQueryParams,
  updateSubQuery,
} from "@evercam/shared/utils"
import { useProjectStore } from "@/stores/projects"

type CameraDialogState = {
  tab: number
  camera: Partial<AdminCamera>
  cachedCameras: Record<CameraExid, AdminCamera>
  cachedSnapshots: Record<CameraExid, string>
  dialog: boolean
  tabFullScreen: boolean
  hideSidebar: boolean
  loading: boolean
  isFetchingSnapshot: boolean
  cameraUpdatedAt: Timestamp
  isExNvrDeviceSelected: boolean
  isInline: boolean
  isCheckingNvrHttpPortStatus: boolean
  isNvrHttpPortOpen: boolean
  exNvrToken: string
  imageElement: HTMLImageElement
}

export const useCameraDialogStore = defineStore({
  id: "cameraDialog",
  state: (): CameraDialogState => ({
    tab: 0,
    camera: {},
    cachedCameras: {},
    cachedSnapshots: {},
    dialog: false,
    tabFullScreen: false,
    isExNvrDeviceSelected: false,
    hideSidebar: false,
    loading: false,
    exNvrToken: "",
    isCheckingNvrHttpPortStatus: true,
    isNvrHttpPortOpen: false,
    isFetchingSnapshot: false,
    cameraUpdatedAt: 0,
    isInline: false,
    imageElement: null,
  }),
  getters: {
    snapshot() {
      return this.cachedSnapshots[this.camera.exid]
    },
    isKit() {
      return this.camera.kitId || /-(\d+)-kit-/g.test(this.camera?.cameraHost)
    },
  },
  actions: {
    cacheCamera({ exid, ...details }) {
      this.cachedCameras = {
        ...this.cachedCameras,
        [exid]: {
          exid,
          ...details,
        },
      }
    },
    invalidateCameraCache({ exid }) {
      const cachedCameras = { ...this.cachedCameras }
      delete cachedCameras[exid]
      this.cachedCameras = { ...cachedCameras }
    },
    cacheSnapshot(payload) {
      this.cachedSnapshots = {
        ...this.cachedSnapshots,
        [this.camera.exid]: payload,
      }
    },
    async fetchCamera(exid) {
      try {
        this.loading = true

        return await AdminApi.cameras.getCameraDetails(exid)
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `Couldn't fetch details for camera "${exid}"`,
          error,
        })

        return {}
      } finally {
        this.loading = false
      }
    },
    async fetchLastestSnapshot() {
      if (this.camera.status !== CameraStatus.Waiting) {
        this.loading = true
        this.isFetchingSnapshot = true
        try {
          let { data } = await EvercamApi.recordings.latest(this.camera.exid, {
            apiId: this.camera.userApiId,
            apiKey: this.camera.userApiKey,
          })
          this.cacheSnapshot(data)
        } catch (error) {
          if (error?.response?.status === 404) {
            useNuxtApp().nuxt2Context.$notifications.warn("Snapshot not found")
          } else {
            useNuxtApp().nuxt2Context.$notifications.error({
              text: "Failed to fetch last snapshot!",
              error,
            })
          }
        } finally {
          this.loading = false
          this.isFetchingSnapshot = false
        }
      }
    },
    async selectCamera(target) {
      let camera

      if (typeof target === "string") {
        const exid = target
        camera = this.cachedCameras[exid]
          ? this.cachedCameras[exid]
          : await this.fetchCamera(exid)
      }

      this.camera = camera

      if (
        camera.exid &&
        !this.cachedSnapshots[camera.exid] &&
        !this.isFetchingSnapshot
      ) {
        this.fetchLastestSnapshot()
      }

      if (camera.exid && !this.cachedCameras[camera.exid]) {
        this.cacheCamera(camera)
      }
    },
    async updateCamera(change) {
      try {
        const updatedCamera = await AdminApi.cameras.update(
          this.camera.exid,
          change
        )
        this.camera = {
          ...this.camera,
          ...updatedCamera,
        }
        this.cacheCamera(this.camera)
        this.cameraUpdatedAt = new Date().getTime()
        useNuxtApp().vue2App.$root.$emit("camera-updated")
        useNuxtApp().nuxt2Context.$notifications.success(
          `Camera "${this.camera.name}" updated successfully`
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to update camera!",
          error,
        })
      }
    },
    async deleteCamera({ payload }) {
      try {
        const reportStore = useReportStore()
        this.loading = true
        await AdminApi.cameras.deleteCamera(this.camera.exid, payload)
        reportStore.items = reportStore.items.filter(
          (item: AdminCamera) => item.id !== this.camera.id
        )
        this.camera = {}
        this.invalidateCameraCache(this.camera)
        this.dialog = false
        useNuxtApp().nuxt2Context.$notifications.success(
          `Camera "${this.camera.name}" has been deleted successfully`
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to delete camera!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    async getExNVRToken({
      cameraExid,
      token,
    }: {
      cameraExid: CameraExid
      token: string
    }) {
      this.exNvrToken = await EvercamApi.cameras.getNvrStreamingToken({
        cameraExid,
        token,
      })
    },
    async loginToExNvr({
      apiUrl,
      username,
      password,
    }: {
      apiUrl: string
      username: string
      password: string
    }) {
      let response = await ExNvrApi.users.login({
        apiUrl,
        username,
        password,
      })
      this.exNvrToken = response.accessToken
    },
    async openDialog(
      { camera }: { camera?: AdminCamera | string; tab?: number },
      shouldUpdateQuery = true
    ) {
      useProjectStore().isDialogOpen = false

      await this.selectCamera(camera)
      const { tab } = getSubQueryParams()
      this.tab = Number(tab) || CAMERA_TABS.SUMMARY
      this.dialog = true

      if (shouldUpdateQuery) {
        updateSubQuery({
          subQueryObj: {
            cameraDialog: true,
            cameraExid: (camera as AdminCamera)?.exid || (camera as string),
          },
          overridePreviousQuery: true,
          pushToHistory: true,
        })
      }
    },
    closeDialog() {
      if (!this.dialog) {
        return
      }

      clearSubQuery({ pushToHistory: true })
      this.dialog = false
    },
  },
})
