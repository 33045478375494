export const strict = false
import { defineStore } from "pinia"

type uploadStats = {
  percentage: number
  totalSize: number
  uploadedSize: number
  isUploading: boolean
  isProcessing: boolean
}

type IngestFileUploader = {
  files: File[]
  selectedFileType: string | undefined
  uploadStats: uploadStats
}

export const useIngestFileUploaderStore = defineStore({
  id: "ingestFileUploader",
  state: (): IngestFileUploader => ({
    files: [],
    selectedFileType: undefined,
    uploadStats: {
      percentage: 0,
      totalSize: 0,
      uploadedSize: 0,
      isUploading: false,
      isProcessing: false,
    },
  }),
})
