import { defineStore } from "pinia"

type BreadcrumbItem = {
  icon?: string
  id?: string
  impersonate?: string
  href?: string
  name?: string
}

type BreadcrumbState = {
  breadcrumbs: BreadcrumbItem[]
}

export const useBreadcrumbStore = defineStore({
  id: "breadcrumb",
  state: (): BreadcrumbState => ({
    breadcrumbs: [],
  }),
})
