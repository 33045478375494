import { defineStore } from "pinia"

type SearchParams = {
  [key: string]: string | number | boolean | string[]
}

type HeaderParams<TItem> = {
  value: string
  text: string
  cellClass: string
  align: string
  sortable: boolean
  filterable: boolean
  visible: boolean
  width: number
  toStringFn: (item: TItem, key: string) => any
}

type ReportState<TItem> = {
  items: TItem[]
  selectedHeaders: HeaderParams<TItem>[]
  selectedItems: TItem[]
  initialSearchParams: SearchParams
  offlineSearchParams: SearchParams
  loading: boolean
  searchFilters: Record<string, any>
  searchParams: Record<string, any>
}

export const useReportStore = defineStore({
  id: "report",
  state: <TItem = object>(): ReportState<TItem> => ({
    items: [],
    selectedHeaders: [],
    selectedItems: [],
    initialSearchParams: {},
    offlineSearchParams: {},
    loading: false,
    searchFilters: {},
    searchParams: {},
  }),
})
