import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import axios from "@evercam/shared/api/client/axios"
import moment from "moment-timezone"
import { AxiosError } from "axios"
import { DateTime } from "@evercam/shared/types"
import { findNearestTimelineIndex } from "@evercam/shared/utils"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useNuxtApp } from "#app"

type BimCompareState = {
  bimAvailableDays: string[] | null
  bimAvailableDaysLabels: string[] | null
  bimDailyEvents: string[] | null
  bimDatetime: DateTime | null
  bimIndexMax: number
  bimIndex: number
  bimMaxDate: DateTime | null
  bimMinDate: DateTime | null
  cameraImage: string | null
  isBimAvailable: boolean
  isBimImageLoading: boolean
  isSnapshotLoading: boolean
  finalView: boolean
  fullDates: string[] | null
  latestBimImage: string | null
  layerId: number | null
  model: string | null
  models: string[] | null
  snapshotCurrentDate: DateTime
  transparency: number
}

export const useBimCompareStore = defineStore({
  id: "bimCompare",
  state: (): BimCompareState => ({
    bimAvailableDays: [],
    bimAvailableDaysLabels: [],
    bimDailyEvents: [],
    bimDatetime: null,
    bimIndexMax: 0,
    bimIndex: 0,
    bimMaxDate: null,
    bimMinDate: null,
    cameraImage: null,
    isBimAvailable: true,
    isBimImageLoading: false,
    isSnapshotLoading: false,
    finalView: true,
    fullDates: [],
    latestBimImage: null,
    layerId: null,
    model: null,
    models: null,
    snapshotCurrentDate: moment().toISOString(),
    transparency: 100,
  }),
  actions: {
    async fetchBimData() {
      try {
        const cameraDialogStore = useCameraDialogStore()
        // Add cancel token for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        this.isBimImageLoading = true

        const bimData = await EvercamApi.bim.getBimModel(
          cameraDialogStore.camera.exid,
          {
            model: this.model,
            timestamp: this.snapshotCurrentDate,
            apiKey: cameraDialogStore.camera?.userApiKey,
            apiId: cameraDialogStore.camera?.userApiId,
          },
          { cancelToken: cancelToken.token }
        )
        if (!bimData) {
          this.isBimAvailable = false

          return
        }

        return bimData
      } catch (error) {
        console.error(error)
      }
    },
    async fetchBimImage() {
      const cameraDialogStore = useCameraDialogStore()
      this.isBimImageLoading = true

      try {
        // Add cancel tokens for the next requests
        const cancelToken = axios.generateCancelTokenSource()
        axios.addCancelToken(cancelToken)

        const payload = {
          model: this.model,
          layerTimestamp: this.snapshotCurrentDate,
          apiKey: cameraDialogStore.camera?.userApiKey,
          apiId: cameraDialogStore.camera?.userApiId,
        }

        const { imageUrl } = await EvercamApi.bim.getBimSnapshot(
          cameraDialogStore.camera.exid,
          this.fullDates[this.bimIndex],
          payload,
          { cancelToken: cancelToken.token }
        )

        this.latestBimImage = imageUrl
      } catch (error) {
        if (!axios.isCancel(error as AxiosError)) {
          useNuxtApp().nuxt2Context.$notifications.warn(
            useNuxtApp().vue2App.$i18n.t("content.bim.no_bim_image") as string
          )
        }
      }
    },
    updateBimProperties(bimData) {
      const {
        model: bimModel,
        models: bimModels,
        dates: bimDates,
      } = bimData || {}
      this.models = bimModels
      this.model = bimModel
      this.finalView = !bimDates?.length
      if (this.finalView) {
        this.resetBimProperties(bimData)

        return
      }

      const lastBimAvailableDaysIndex = bimDates?.length - 1
      this.updateBimDateProperties(bimData)
      const nearestTimelineIndex = findNearestTimelineIndex(
        this.snapshotCurrentDate,
        this.bimAvailableDays
      )
      const nearestTimelineDate = bimDates[nearestTimelineIndex]

      this.latestBimImage = ""

      const bimMaxDate = bimDates?.[lastBimAvailableDaysIndex]
      this.bimMaxDate = moment(bimMaxDate).isValid()
        ? moment(bimMaxDate).format("YYYY-MM-DD")
        : ""
      const bimMinDate = bimDates?.[0]
      this.bimMinDate = moment(bimMinDate).isValid()
        ? moment(bimMinDate).format("YYYY-MM-DD")
        : ""
      this.bimDatetime = moment(nearestTimelineDate).format("YYYY-MM-DD")
      this.bimIndexMax = lastBimAvailableDaysIndex
      this.bimIndex = nearestTimelineIndex as number
      this.fullDates = bimDates
    },
    updateBimDateProperties(bimInfo) {
      const { dates } = bimInfo || {}
      const lastBimAvailableDaysIndex = dates?.length - 1

      const data = dates?.reduce(
        (acc, date, index) => {
          const day = moment(date).format("DD-MM-YYYY HH:mm")
          const dayLabel = [0, lastBimAvailableDaysIndex].includes(index)
            ? moment(date).format("DD-MM-YYYY")
            : ""
          const dailyEventDate = moment(date).format("YYYY-MM-DD")

          return {
            availableDays: [...acc.availableDays, day],
            availableDaysLabels: [...acc.availableDaysLabels, dayLabel],
            dailyEvents: [...acc.dailyEvents, dailyEventDate],
          }
        },
        {
          availableDays: [],
          availableDaysLabels: [],
          dailyEvents: [],
        }
      )

      this.bimAvailableDays = data.availableDays
      this.bimAvailableDaysLabels = data.availableDaysLabels
      this.bimDailyEvents = data.dailyEvents
    },
    resetBimProperties(bimInfo) {
      this.bimMinDate = null
      this.bimMaxDate = null
      this.bimDatetime = undefined
      this.latestBimImage = bimInfo?.imageUrl
      this.bimAvailableDays = []
      this.bimIndexMax = 0
      this.bimIndex = 0
      this.bimAvailableDaysLabels = []
      this.fullDates = []
    },
  },
})
