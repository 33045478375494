import { defineNuxtPlugin } from "#app"
import axios from "@evercam/shared/api/client/axios"
import { OnRequestExpiredTokenInterceptor } from "@evercam/shared/api/client/interceptors"

export default defineNuxtPlugin((nuxtApp) => {
  const { $auth, $errorTracker } = nuxtApp.nuxt2Context
  const {
    apiURL,
    aiApiUrl,
    ingestApiUrl,
    stagingAiApiUrl,
    posthogApiUrl,
    posthogProjectId,
    posthogPrivateApiKey,
    firebaseVideowallUrl,
    weatherApiBaseUrl,
    evercamLabsUrl,
    snapshotsURL,
    firebaseDbLink,
  } = nuxtApp.$config.public

  axios.addEnvironmentVariables({
    baseUrl: apiURL,
    aiApiUrl,
    ingestApiUrl,
    stagingAiApiUrl,
    posthogApiUrl,
    posthogProjectId,
    posthogPrivateApiKey,
    firebaseVideowallUrl,
    weatherApiBaseUrl,
    evercamLabsUrl,
    snapshotsURL,
    firebaseDbLink,
    getAuthToken: () => $auth.getToken(),
    errorLogger: $errorTracker.leaveBreadcrumb,
    isStaging: window.location.hostname.includes("staging"),
  })

  const onRequest = (request) =>
    OnRequestExpiredTokenInterceptor(
      request,
      () => $auth?.hasToken() && $auth?.isTokenExpired(),
      $auth?.logout
    )

  axios.addRequestInterceptor(onRequest)
})
