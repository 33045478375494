import { VEHICLE_TYPE_IDS } from "@evercam/shared/constants/gateReport"
import { AiApi } from "@evercam/shared/api/aiApi"
import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import { useAccountStore } from "@/stores/account"

export enum DetectionModel {
  YoloTrucks = "yolo_trucks",
  YoloCounting = "yolo_counting",
  YoloV5 = "yolov5",
  YoloV8Trucks = "yolov8_trucks",
}

interface ParametersState {
  parameters: Parameters
  defaultParameters: Parameters
}
type Parameters = {
  id?: number
  cameraex?: string
  processingType?: string
  startDate?: number
  endDate?: number
  skipWeekend?: boolean
  hourSiteOpening?: number
  hourSiteClosure?: number
  siteRangeSetterValue?: Array<number>
  gateReportActive?: boolean
  countingActive?: boolean
  dangerZones?: boolean
  workflows?: boolean
  analyticsVisualize?: boolean
  queueSystem?: string
  reprocess?: boolean
  framesToSkip?: number
  detectionModel?: string
  detectionThreshold?: number
  detectionClasses?: string[] | string
  printLogsFrame?: number
  crossingVectorAngleLimit?: number
  minTruckLife?: number
  insideMinTime?: number
  trackerMaxAge?: number
  trackerMinHit?: number
  snapshotUpload?: boolean
  snapshotPerSecond?: number
  deepEventEndpointUrl?: string
  timestampCalculation?: string

  matchingEmbeddedDstCoeff?: number
  matchingTimeCoeff?: number
  matchingQueueOrderCoeff?: number

  rabbitmqServerUrl?: string
  rabbitmqEventsQueue?: string
  rabbitmqEventsExchange?: string
  rabbitmqCountingQueue?: string
  rabbitmqCountingExchange?: string
}

export const useParametersStore = defineStore({
  id: "parameters",
  state: (): ParametersState => ({
    parameters: {},
    defaultParameters: {
      cameraex: "",
      processingType: "continuous",
      startDate: new Date().setHours(5, 0, 0),
      endDate: new Date().setHours(20, 0, 0),
      skipWeekend: true,
      hourSiteOpening: 5,
      hourSiteClosure: 20,
      siteRangeSetterValue: [5, 20],
      gateReportActive: true,
      countingActive: false,
      dangerZones: false,
      workflows: false,
      analyticsVisualize: false,
      queueSystem: "RABBITMQ",
      reprocess: false,
      framesToSkip: 0,
      detectionModel: DetectionModel.YoloTrucks,
      detectionThreshold: 0.3,
      detectionClasses: VEHICLE_TYPE_IDS,
      printLogsFrame: 1000,
      crossingVectorAngleLimit: 100,
      minTruckLife: 5,
      insideMinTime: 300,
      trackerMaxAge: 50,
      trackerMinHit: 2,
      snapshotUpload: false,
      snapshotPerSecond: 0.2,
      deepEventEndpointUrl: "http://95.217.34.156:8000/",
      timestampCalculation: "ocr",

      // MATCHING PARAMETERS
      matchingEmbeddedDstCoeff: 0.6,
      matchingTimeCoeff: 0.2,
      matchingQueueOrderCoeff: 0.2,

      // RABBIT MQ CONFIG
      rabbitmqServerUrl: "rabbitmq.evercam.io",
      rabbitmqEventsQueue: "event-edge-analytics-q",
      rabbitmqEventsExchange: "event-edge-analytics-x",
      rabbitmqCountingQueue: "counting-analytics-q",
      rabbitmqCountingExchange: "counting-analytics-x",
    },
  }),
  actions: {
    async fetchParameters({ cameraId }) {
      try {
        const parameters = await AiApi.analyticsParameters.getParameters(
          cameraId
        )

        this.parameters = {
          ...(parameters ? parameters : this.defaultParameters),
          cameraex: cameraId,
          detectionClasses:
            ((parameters as Parameters)?.detectionClasses as string)?.split(
              ","
            ) || VEHICLE_TYPE_IDS,
        }
      } catch (e) {
        useNuxtApp().nuxt2Context.$notifications.error({ text: e })
        this.parameters = { ...this.defaultParameters, cameraex: cameraId }
      }
    },
    async upsertParameters(payload) {
      try {
        if (payload?.id) {
          const updatedBy = useAccountStore().email
          await AiApi.analyticsParameters.updateParameters({
            updatedBy,
            ...payload,
          })
        } else {
          await AiApi.analyticsParameters.createParameters(payload)
        }
        useNuxtApp().nuxt2Context.$notifications.success(
          "The parameters saved successfully"
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't save parameters data!",
          error,
        })
      }
    },
  },
})
