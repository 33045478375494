import { defineStore } from "pinia"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useNuxtApp } from "#app"

type Recipients = {
  cameras?: string[]
  projects?: string[]
  users?: number[]
}

type EmailingState = {
  sender: Record<string, any>
  recipients: Recipients
  recipientsDialog: boolean
  subject: string
  selectedUsers: Record<string, any>[]
  selectedProjects: Record<string, any>[]
  selectedCameras: Record<string, any>[]
}

export const useEmailingStore = defineStore({
  id: "emailing",
  state: (): EmailingState => ({
    sender: null,
    recipients: {},
    recipientsDialog: false,
    subject: "",
    selectedUsers: [],
    selectedProjects: [],
    selectedCameras: [],
  }),
  actions: {
    async performEmailing({ template, params }) {
      try {
        await AdminApi.emailing.performEmailing(template, params)
        useNuxtApp().nuxt2Context.$notifications.success(
          "The emails have been sent successfully"
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `Could not perform the emailing with template: "${template}", params: ${JSON.stringify(
            params,
            null,
            2
          )}`,
          error,
        })
      }
    },
  },
})
