import { defineStore } from "pinia"

interface BimTransparencyState {
  isSelecting: boolean
  isEditMode: boolean
  shapes: string[][] | []
  isDrawing: boolean
  isDeleting: boolean
  selectedShapeIndex: number
}

export const useBimTransparencyStore = defineStore({
  id: "bimTransparency",
  state: (): BimTransparencyState => ({
    isSelecting: false,
    isEditMode: false,
    shapes: [],
    isDrawing: false,
    isDeleting: false,
    selectedShapeIndex: Infinity,
  }),

  actions: {
    deleteSelectedShape() {
      this.shapes = [
        ...this.shapes.slice(0, this.selectedShapeIndex),
        ...this.shapes.slice(this.selectedShapeIndex + 1),
      ]
    },
  },
})
