<template>
  <v-app>
    <TheAppBar />
    <TheSnackbar />
    <TheSidebar />
    <TheGlobalDialogs />
    <v-main>
      <div class="d-flex align-center">
        <TheBreadcrumbs
          v-if="breadcrumbStore.breadcrumbs.length"
          :items="breadcrumbStore.breadcrumbs"
        />
        <div class="ml-auto">
          <portal-target name="breadcrumbs-right"></portal-target>
        </div>
      </div>
      <v-divider v-if="breadcrumbStore.breadcrumbs.length" />
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from "@/components/TheAppBar"
import TheSnackbar from "@/components/TheSnackbar"
import TheSidebar from "@/components/TheSidebar"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import TheGlobalDialogs from "@/components/TheGlobalDialogs"

import { mapStores } from "pinia"
import { useBreadcrumbStore } from "@/stores/breadcrumb"

export default {
  components: {
    TheAppBar,
    TheSnackbar,
    TheSidebar,
    TheBreadcrumbs,
    TheGlobalDialogs,
  },
  middleware: ["auth", "reportsAccess", "breadcrumbs"],
  head() {
    return {
      htmlAttrs: {
        class: "overflow-y-auto",
      },
    }
  },
  computed: {
    ...mapStores(useBreadcrumbStore),
  },
}
</script>

<style lang="scss">
.v-main__wrap {
  overflow: hidden;
}
</style>
