import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

interface SnapshotState {
  beforedate: Date | null
  afterdate: Date | null
  beforeimage: string
  afterimage: string
  lastestImage: string
  oldestImageJson: string[] | null
  latestImageJson: string[] | null
}

export const useSnapshotStore = defineStore({
  id: "snapshot",
  state: (): SnapshotState => ({
    beforedate: null,
    afterdate: null,
    beforeimage: null,
    afterimage: null,
    lastestImage: null,
    oldestImageJson: null,
    latestImageJson: null,
  }),

  actions: {
    async updateFrames(frameData) {
      try {
        const before = await EvercamApi.recordings.oldest(frameData.camera, {
          apiKey: frameData.userApiKey,
          apiId: frameData.userApiId,
        })
        await this.setOldestImage({ before })
      } catch (e) {
        if (e.response?.status === 404) {
          const before = e.response.data
          await this.setOldestImage({ before })
        } else {
          const before = { data: "unavailable.jpg", createdAt: null }
          await this.setOldestImage({ before })
        }
      }

      try {
        const after = await EvercamApi.recordings.latest(frameData.camera, {
          apiKey: frameData.userApiKey,
          apiId: frameData.userApiId,
        })
        await this.setLatestImage({ after })
      } catch (e) {
        if (e.response) {
          if (e.response?.status === 404) {
            const after = e.response.data
            after.createdAt = new Date().toISOString()
            await this.setLatestImage({ after })
          }
        } else {
          const after = {
            data: "unavailable.jpg",
            createdAt: new Date().toISOString(),
          }
          await this.setLatestImage({ after })
        }
      }
    },
    setOldestImage({ before }) {
      this.beforedate = before.createdAt
      this.beforeimage = before.data
      this.oldestImageJson = before
    },
    setLatestImage({ after }) {
      this.afterdate = after.createdAt
      this.afterimage = after.data
      this.latestImageJson = after
    },
  },
})
