import { defineStore } from "pinia"
import {
  AdminCamera,
  CameraExid,
  ExNvrDeviceConfig,
  ExNvrLoginResponse,
  MilesightRequestParams,
  NvrModel,
  TaskStatus,
  ExNvrHealthCheckTaskId,
  ExNvrHealthCheckTaskResult,
  ExNvrHealthCheckTask,
  ExNvrRecordingInterval,
} from "@evercam/shared/types"
import { TimedRequest } from "@evercam/shared/api/client/axios"
import { ExNvrApi } from "@evercam/shared/api/exNvrApi"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import { AdminApi } from "@evercam/shared/api/adminApi"
import Vue from "vue"

interface ExNvrHealthCheckState {
  cameras: AdminCamera[]
  healthCheckTasksByCameraExid: Record<
    CameraExid,
    Array<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
  >
}

export const useExNvrHealthCheckStore = defineStore({
  id: "exNvrHealthCheck",
  state: (): ExNvrHealthCheckState => ({
    cameras: [] as AdminCamera[],
    healthCheckTasksByCameraExid: {},
  }),
  actions: {
    async init() {
      await this.fetchExNvrCameras()
      this.initTasks()
    },
    initTasks() {
      ;(this.cameras as AdminCamera[]).forEach(this.resetTasksForCamera)
    },
    resetTasksForCamera(camera: AdminCamera) {
      const taskIds = Object.values(ExNvrHealthCheckTaskId)
      const placeholderTasks = taskIds.map((taskId) => ({
        id: taskId,
        status: TaskStatus.Idle,
        error: null,
        duration: 0,
        result: null,
      }))

      Vue.set(this.healthCheckTasksByCameraExid, camera.exid, [
        ...placeholderTasks,
      ])
    },
    async performHealthCheckForCamera(cameraExid: CameraExid) {
      const camera = this.getCameraByExid(cameraExid)
      this.resetTasksForCamera(camera)

      await Promise.all([
        this.loginToExNvr(camera).then(() =>
          this.fetchExNvrDeviceConfig(camera).then(() =>
            this.fetchExNvrRecordings(camera)
          )
        ),
        this.fetchCameraSystemInfo(camera),
        this.fetchCameraTimeInfo(camera),
        this.fetchCameraStreamsInfo(camera),
        this.fetchCameraNetworkInfo(camera),
        this.fetchCameraOsdInfo(camera),
        this.fetchCameraStorageInfo(camera),
      ])
    },
    async performHealthCheckTask<T extends ExNvrHealthCheckTaskId>({
      cameraExid,
      taskId,
      resultProvider,
    }: {
      cameraExid: CameraExid
      taskId: T
      resultProvider: () => TimedRequest<ExNvrHealthCheckTaskResult<T>>
    }) {
      let status = TaskStatus.Loading
      let err: Error | undefined
      let result: ExNvrHealthCheckTaskResult<T> | undefined
      let timeElapsed = 0

      this.updateTask(cameraExid, taskId, {
        status,
      })

      try {
        const { data, duration, error } = await resultProvider()
        if (error) {
          err = error
          status = TaskStatus.Error
        } else {
          result = data
          status = TaskStatus.Success
        }
        timeElapsed = duration
      } catch (e) {
        err = e as Error
        status = TaskStatus.Error
      } finally {
        this.updateTask(cameraExid, taskId, {
          status,
          result,
          error: err,
          duration: timeElapsed,
        })
      }
    },
    updateTask(
      cameraExid: CameraExid,
      taskId: ExNvrHealthCheckTaskId,
      payload: Partial<ExNvrHealthCheckTask<ExNvrHealthCheckTaskId>>
    ) {
      const taskIndex = this.healthCheckTasksByCameraExid[cameraExid].findIndex(
        (t) => t.id === taskId
      )
      const task = this.healthCheckTasksByCameraExid[cameraExid][taskIndex]

      Vue.set(this.healthCheckTasksByCameraExid[cameraExid], taskIndex, {
        ...task,
        ...payload,
      })
    },
    async fetchExNvrCameras() {
      const { items } = await AdminApi.cameras.getCameras({
        params: {
          sort: "create_at|desc",
          nvrModel: NvrModel.ExNvr,
          limit: 1500,
          page: 1,
        },
      })
      this.cameras = items
    },
    async loginToExNvr(camera: AdminCamera) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: ExNvrHealthCheckTaskId.ExNvrLogin,
        resultProvider: () => {
          return ExNvrApi.users.login(
            {
              apiUrl: this.getExNvrApiUrl(camera),
              username: camera.nvrUsername,
              password: camera.nvrPassword,
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<ExNvrLoginResponse>
        },
      })
    },
    async fetchExNvrDeviceConfig(camera: AdminCamera) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: ExNvrHealthCheckTaskId.ExNvrConfigCheck,
        resultProvider: () => {
          return ExNvrApi.devices.getDeviceConfig(
            {
              deviceId: camera.nvrDeviceId,
              apiUrl: this.getExNvrApiUrl(camera),
              token: this.getExNvrToken(camera),
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<ExNvrDeviceConfig>
        },
      })
    },
    async fetchExNvrRecordings(camera: AdminCamera) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: ExNvrHealthCheckTaskId.ExNvrRecordings,
        resultProvider: () => {
          return ExNvrApi.devices.getAvailableRecordings(
            {
              deviceId: camera.nvrDeviceId,
              apiUrl: this.getExNvrApiUrl(camera),
              token: this.getExNvrToken(camera),
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<ExNvrRecordingInterval[]>
        },
      })
    },
    async performCameraTask<T extends ExNvrHealthCheckTaskResult<any>>(
      camera: AdminCamera,
      taskId: ExNvrHealthCheckTaskId,
      apiCall: (params: MilesightRequestParams, config: any) => Promise<T>
    ) {
      await this.performHealthCheckTask({
        cameraExid: camera.exid,
        taskId: taskId,
        resultProvider: () => {
          return apiCall(
            {
              host: camera.cameraHost,
              httpPort: camera.cameraHttpPort,
              username: camera.cameraUsername,
              password: camera.cameraPassword,
            },
            {
              timing: true,
            }
          ) as unknown as TimedRequest<T>
        },
      })
    },
    async fetchCameraStreamsInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraStreamsConfigCheck,
        EvercamLabsApi.milesight.getVideoConfig
      )
    },
    async fetchCameraNetworkInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraNetworkConfigCheck,
        EvercamLabsApi.milesight.getNetworkConfiguration
      )
    },
    async fetchCameraSystemInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraSystemConfigCheck,
        EvercamLabsApi.milesight.getSystemSettings
      )
    },
    async fetchCameraTimeInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraTimeConfigCheck,
        EvercamLabsApi.milesight.getTimeSettings
      )
    },
    async fetchCameraOsdInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraOsdConfigCheck,
        EvercamLabsApi.milesight.getOsdConfiguration
      )
    },
    async fetchCameraStorageInfo(camera: AdminCamera) {
      await this.performCameraTask(
        camera,
        ExNvrHealthCheckTaskId.CameraStorageConfigCheck,
        EvercamLabsApi.milesight.getSdCardInfo
      )
    },
  },
  getters: {
    getCameraByExid: (state) => (exid: CameraExid) => {
      return state.cameras.find((c) => c.exid === exid) as AdminCamera
    },
    getExNvrApiUrl: () => (camera: AdminCamera) => {
      return `${camera.nvrScheme}://${camera.nvrHost}:${camera.nvrHttpPort}`
    },
    getExNvrToken: (state) => (camera: AdminCamera) => {
      const exNvrLoginResponse = state.healthCheckTasksByCameraExid[
        camera.exid
      ]?.find((t) => t.id === ExNvrHealthCheckTaskId.ExNvrLogin)
        ?.result as ExNvrLoginResponse

      return exNvrLoginResponse?.accessToken
    },
  },
})
