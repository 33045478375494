import { GateReportVerifiedDay } from "@evercam/shared/types"
import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import { AiApi } from "@evercam/shared/api/aiApi"
import { useAccountStore } from "@/stores/account"
import moment from "moment-timezone"

type DayVerificationState = {
  verifiedDays: GateReportVerifiedDay[]
  isLoading: boolean
}

export const useDayVerificationStore = defineStore({
  id: "dayVerification",
  state: (): DayVerificationState => ({
    verifiedDays: [],
    isLoading: false,
  }),
  actions: {
    async verifyDay({ date, projectExid, camerasExid }) {
      this.isLoading = true
      const day = moment(date).format("YYYY-MM-DD")
      const verifiedBy = useAccountStore().email
      try {
        await AiApi.gateReport.verifyDay({
          day,
          camerasExid,
          projectExid,
          verifiedBy,
        })
        const newVerifiedDays = camerasExid.map((exid) => ({
          day,
          verifiedBy,
          projectExid,
          cameraExid: exid,
        }))
        this.verifiedDays = [...this.verifiedDays, ...newVerifiedDays]
        useNuxtApp().nuxt2Context.$notifications.success(
          "The day has been marked as verified successfully"
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `Failed to verify the day ${day}`,
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    isDayVerified(date) {
      return !!this.verifiedDays.find(({ day }) => {
        return date === day
      })
    },
    async fetchVerifiedDays({ projectExid, camerasExid }) {
      this.isLoading = true
      try {
        const { items } = await AiApi.gateReport.getVerifiedDays(projectExid, {
          camerasExid,
          limit: 10000,
        })
        this.verifiedDays = items
      } catch (error) {
        this.verifiedDays = []
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Couldn't fetch gate report verified days!",
          error,
        })
        throw error
      } finally {
        this.isLoading = false
      }
    },
  },
})
